import "./Quiz.scss";
import { useState, useEffect, useLayoutEffect } from "react";
import {
  arrayOfObjToArray,
  checkArrayCorrectAns,
  jsonDecryptAES,
} from "../../utils/common";
import { adminPath } from "../../constants/api";
import axios from "axios";
import SequenceAnswer from "../../Quiz/SequenceAnswer";
import MatchAnswer from "../../Quiz/MatchAnswer";
import Timer from "../../Quiz/Timer";
import MCQ from "../../Quiz/MCQ";
import TrueOrFalseOption from "../../Quiz/TrueOrFalseOption";
import MultiOption from "../../Quiz/MultiOption";
import { imagePath } from "../../constants/api";
import QuizReview from "./QuizReview";
import { useNavigate, useLocation } from "react-router-dom";
import { quizQuestionDisclimer } from "../../constants/constants";
import authInterceptor from "../../utils/authInterceptor";
import MyTimer from "../../Quiz/MyTimer";
import dayjs from "dayjs";
const Quiz = () => {
  const navigate = useNavigate();
  const [questionList, setQuestionList] = useState<any[]>([]);
  const [pqdId, setpqdId] = useState<any>();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [quizResults, setQuizResults] = useState<any>([]);
  const [entrieData, setEntrieData] = useState<any>([]);
  const [currentOptionSelected, setCurrentOptionSelected] = useState<any>(null);
  const [showNextButton, setShowNextButton] = useState(false);
  const [score, setScore] = useState(0);
  const [quizStart, setQuizStart] = useState<any>();
  const [isReview, setIsReview] = useState(false);
  const [isQuizSubmited, setIsQuizSubmited] = useState(false);
  const [duration, setDuration] = useState<any>();
  const [checkForSkippedQuestionOnly, setCheckForSkippedQuestionOnly] =
    useState(false);
  const [skipedQuestion, setSkipedQuestion] = useState<number[]>([]);
  const [showReviewBtn, setShowReviewBtn] = useState(false);
  const [levelData, setLevelData] = useState<any>({
    L1: 1,
    L2: 2,
    L3: 3,
  });
  const { state } = useLocation();
  const { testId } = state || {};
  useEffect(() => {
    setQuizStart(new Date());
  }, []);
  // useEffect(() => {
  //   const handleBeforeUnload = (event: {
  //     preventDefault: () => void;
  //     returnValue: string;
  //   }) => {
  //     localStorage.setItem("isPageRefreshed", "true");
  //   };

  //   const checkRefresh = () => {
  //     const isRefreshed = localStorage.getItem("isPageRefreshed");
  //     if (isRefreshed) {
  //       handleScore("refreshed");
  //     } else {
  //       localStorage.removeItem("isPageRefreshed");
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   checkRefresh();
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  // const onVisibilityChange = () => {
  //   if (document.visibilityState === "visible") {
  //     getMallData("malpractice");
  //   }
  // };

  // useLayoutEffect(() => {
  //   document.addEventListener("visibilitychange", onVisibilityChange);
  //   return () =>
  //     document.removeEventListener("visibilitychange", onVisibilityChange);
  // }, []);
  useEffect(() => {
    if (questionList.length === currentQuestion) {
      if (skipedQuestion && skipedQuestion.length == 0) {
        checkForSkippedQuestionOnly && setCheckForSkippedQuestionOnly(false);
        return;
      }
      setCheckForSkippedQuestionOnly(true);
      setCurrentQuestion(skipedQuestion[0]);
      setShowNextButton(false);
    }
    if (
      checkForSkippedQuestionOnly &&
      skipedQuestion.length == 1 &&
      skipedQuestion[0] == currentQuestion
    ) {
      setShowReviewBtn(true);
    }
    if (
      skipedQuestion.length == 0 &&
      questionList.length === currentQuestion + 1
    ) {
      setShowReviewBtn(true);
    }
  }, [currentQuestion]);

  const getTestData = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}tempdata/${testId}`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      if (decryptAESData && decryptAESData.length > 0) {
        setpqdId(decryptAESData[0]?.questionData?.pqdId);

        const time = new Date();
        time.setSeconds(
          time.getSeconds() +
            60 * Number(decryptAESData[0]?.questionData?.duration)
        );

        setDuration(time);
        setEntrieData(decryptAESData[0]?.questionData);
        localStorage.setItem(
          "entrieDataLS",
          JSON.stringify(decryptAESData[0]?.questionData)
        );
        setQuestionList(decryptAESData[0]?.questionData?.quizQuestionList);
      } else {
        navigate("/home");
      }
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const getLevelType = async () => {
    try {
      const response: any = await authInterceptor.get(
        `${adminPath}common/FE/Level`
      );
      const decryptAESData: any = jsonDecryptAES(response?.data?.list);
      let obj: any = {};
      decryptAESData[0].levelArray.map((data: any) => {
        obj[data.code] = data.baseMark;
      });
      console.log(obj);
      setLevelData(obj);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const reloaddeleteTempData = async () => {
    try {
      await authInterceptor.delete(`${adminPath}tempdata/${testId}`);
      navigate("/home");
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const deleteTempData = async () => {
    try {
      await authInterceptor.delete(`${adminPath}tempdata/${testId}`);
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  useEffect(() => {
    getLevelType();
    getTestData();
  }, []);

  const getMallData = async (msg: any) => {
    try {
      let scoreObj: any = {
        isPass: false,
        correctList: 0,
        score: 0,
        percentage: 0,
        count: 0,
        gradeData: {
          points: 0,
          grade: "F",
          text: "Fail",
          from: 0,
          to: 39.99,
        },
        examstatus: msg,
      };

      const entrieDataLS: any = JSON.parse(
        localStorage.getItem("entrieDataLS") || ""
      );
      if (entrieDataLS.isSpecialityPackage) {
        if (entrieDataLS?.type === "Module") {
          scoreObj["moduleCode"] = entrieDataLS?.moduleCode;
        }
        scoreObj["type"] = entrieDataLS?.type;
        const completedExam = [...entrieDataLS?.examResult];
        completedExam.push(scoreObj);
        await authInterceptor.patch(
          `${adminPath}PQD/FE/Speciality/${entrieDataLS?.pqdId}/${entrieDataLS?.selectedExam}`,
          completedExam
        );
        localStorage.removeItem("entrieDataLS");
        reloaddeleteTempData();
      } else {
        await authInterceptor.patch(
          `${adminPath}PQD/FE/${entrieDataLS?.pqdId}/${entrieDataLS?.selectedExam}`,
          scoreObj
        );
        localStorage.removeItem("entrieDataLS");
        reloaddeleteTempData();
      }
    } catch (error: any) {
      if (error.response) {
      }
    }
  };

  const validateAnswer = async (selectedOption: any) => {
    if (skipedQuestion && skipedQuestion.length > 0) {
      const skipedQuestionArray = skipedQuestion.filter(
        (question) => question !== currentQuestion
      );
      setSkipedQuestion(skipedQuestionArray);
    }
    setCurrentOptionSelected(selectedOption);
    setShowNextButton(true);
  };
  const finelResult = async (
    correctAnswer: any,
    questiontype: string,
    isCorrect: any,
    objData: any,
    selectedOption: any
  ) => {
    const newData = [...quizResults];
    newData.push({
      correctAnswer,
      questiontype,
      isCorrect,
      objData,
      selectedOption,
    });
    setQuizResults(newData);
  };
  const handleScore = (status: string) => {
    const passMark =
      (Number(entrieData?.passPercent) / 100) * entrieData?.totalMark;
    const perc = ((score / entrieData?.totalMark) * 100).toFixed(0);
    const isPass = score > passMark || passMark === score ? true : false;
    const correctList = quizResults.filter((data: any) => data.isCorrect);
    const currentTime = dayjs(new Date());
    const oldTime = dayjs(quizStart);
    const diff = currentTime.diff(oldTime, "hour");
    const diffminute = currentTime.diff(oldTime, "minute", true);
    const minsSecs = diffminute.toFixed(2).split(".");
    const minsValue = minsSecs[0] + " mins " + minsSecs[1] + " secs";
    const duration = diff > 0 ? `${diff}:${minsValue}` : minsValue;
    try {
      authInterceptor
        .post(`${adminPath}tempdata/score/`, {
          questionData: {
            totalMark: score,
            overAllMark: entrieData?.totalMark,
            isPass: isPass,
            pqdId: pqdId,
            selectedExamId: entrieData?.selectedExam,
            moduleCode: entrieData?.moduleCode,
            isSpecialityPackage: entrieData?.isSpecialityPackage,
            type: entrieData?.type,
            percentage: Number(perc),
            correctList: correctList.length,
            quizQuestionList: quizResults,
            examResult: entrieData?.examResult ?? [],
            entrieData: {
              examtype: entrieData.examtype,
              subName: entrieData.subName,
            },
            examstatus: status,
            examDuration: duration,
          },
        })
        .then((response: any) => {
          if (
            status === "refreshed" ||
            status === "malpractice" ||
            status === "Refresh or Closed"
          ) {
            reloaddeleteTempData();
          } else {
            deleteTempData();
            navigate("/score", {
              state: { scoreId: response?.data?.id, title: state?.title,testType:state?.testType },
            });
          }
        });
    } catch (error: any) {
      if (error.response) {
      }
    }
  };
  const handleNext = async (isFinalSubmit: boolean) => {
    if (checkForSkippedQuestionOnly) {
      if (skipedQuestion && skipedQuestion.length > 0) {
        setCurrentQuestion(skipedQuestion[0]);
      } else {
        setCheckForSkippedQuestionOnly(false);
      }
    }

    if (!isFinalSubmit && !checkForSkippedQuestionOnly) {
      !showReviewBtn && setCurrentQuestion(currentQuestion + 1);
      setShowNextButton(false);
    }
    if (questionList[currentQuestion]?.questiontype === "sequence") {
      const selectedArry = await arrayOfObjToArray(
        currentOptionSelected,
        "order"
      );
      const optionSquAnswer = await arrayOfObjToArray(
        questionList[currentQuestion]?.optionSqu,
        "seqAnswer"
      );
      const totalCount = checkArrayCorrectAns(selectedArry, optionSquAnswer);
      const isCorrect =
        JSON.stringify(selectedArry) == JSON.stringify(optionSquAnswer);
      await finelResult(
        optionSquAnswer,
        questionList[currentQuestion]?.questiontype,
        isCorrect,
        questionList[currentQuestion],
        selectedArry
      );
      if (isCorrect) {
        const mark = levelData[questionList[currentQuestion]?.level];
        setScore(score + Number(mark));
      } else if (totalCount > 0) {
        const mark = levelData[questionList[currentQuestion]?.level];
        const graceMark = (Number(mark) / Number(totalCount)).toFixed(2);
        setScore(score + Number(graceMark));
      }
    } else if (questionList[currentQuestion]?.questiontype === "match") {
      const selectedArry = await arrayOfObjToArray(
        currentOptionSelected,
        "order"
      );
      const optionSquAnswer = await arrayOfObjToArray(
        questionList[currentQuestion]?.optionMatch,
        "matchOrder"
      );
      const totalCount = checkArrayCorrectAns(selectedArry, optionSquAnswer);

      const isCorrect =
        JSON.stringify(selectedArry) == JSON.stringify(optionSquAnswer);
      await finelResult(
        optionSquAnswer,
        questionList[currentQuestion]?.questiontype,
        isCorrect,
        questionList[currentQuestion],
        selectedArry
      );
      if (isCorrect) {
        const mark = levelData[questionList[currentQuestion]?.level];
        setScore(score + Number(mark));
      } else if (totalCount > 0) {
        const mark = levelData[questionList[currentQuestion]?.level];
        const graceMark = (Number(mark) / Number(totalCount)).toFixed(2);
        setScore(score + Number(graceMark));
      }
    } else if (questionList[currentQuestion]?.questiontype === "trueorfalse") {
      const isCorrect =
        currentOptionSelected ===
        questionList[currentQuestion]?.truefalseAnswer;
      await finelResult(
        questionList[currentQuestion]?.truefalseAnswer,
        questionList[currentQuestion]?.questiontype,
        isCorrect,
        questionList[currentQuestion],
        currentOptionSelected
      );
      if (isCorrect) {
        const mark = levelData[questionList[currentQuestion]?.level];
        setScore(score + Number(mark));
      }
    } else if (
      questionList[currentQuestion]?.questiontype === "multipleOptions"
    ) {
      const correct_option = questionList[currentQuestion]?.optionArray?.filter(
        (data: any) => data.checked
      );
      const selectedOption = currentOptionSelected?.filter(
        (data: any) => data.checked && data.selectedCheck
      );
      const isCorrect = correct_option?.length === selectedOption?.length;
      const negativeMarks = correct_option?.length - selectedOption?.length;
      await finelResult(
        questionList[currentQuestion]?.optionArray,
        questionList[currentQuestion]?.questiontype,
        isCorrect,
        questionList[currentQuestion],
        currentOptionSelected
      );
      if (isCorrect) {
        const mark = levelData[questionList[currentQuestion]?.level];
        setScore(score + Number(mark));
      }
    } else {
      let correct_option = questionList[currentQuestion]?.optionArray.filter(
        (data: any) => data.checked
      );
      await finelResult(
        correct_option[0],
        questionList[currentQuestion]?.questiontype,
        currentOptionSelected?.checked,
        questionList[currentQuestion],
        currentOptionSelected
      );
      if (currentOptionSelected?.checked) {
        const mark = levelData[questionList[currentQuestion]?.level];
        setScore(score + Number(mark));
      }
    }
    setCurrentOptionSelected(null);
  };
  const handleFinalSubmit = () => {
    handleScore("Success");
    handleNext(true);
    setIsQuizSubmited(true);
  };
  const handleReview = () => {
    setCurrentQuestion(questionList.length - 2);
    handleNext(false);
    setIsReview(true);
  };
  const handleSkip = (currentQuestion: any) => {
    !skipedQuestion.includes(currentQuestion) &&
      setSkipedQuestion((skipeQuestion) => [...skipeQuestion, currentQuestion]);
    setCurrentQuestion(currentQuestion + 1);
  };

  const updateQuizAnswer = async (selectedOption: any, index: number) => {
    const list = [...quizResults];
    list[index].selectedOption = selectedOption;
    setQuizResults(list);
  };
  return (
    <div className="quizWrapper">
      <div className="container quizWrapperContainer">
        <div className="quizHeader">
          {!isReview ? (
            <p className="noOfQuestion">
              Number of Questions : {currentQuestion + 1}/{questionList.length}
            </p>
          ) : (
            <p className="noOfQuestion">Review Questions</p>
          )}
          <span className="timeleft-wrapper">
            {/* {duration && (
              <Timer deleteTempData={handleScore} duration={30} />
            )} */}
            {duration && (
              <MyTimer
                deleteTempData={handleScore}
                expiryTimestamp={duration}
              />
            )}
          </span>
        </div>
        {isReview ? (
          <div className="quizReviewList">
            <QuizReview
              quizResults={quizResults}
              updateQuizAnswer={(value: any, index: any) =>
                updateQuizAnswer(value, index)
              }
            />
          </div>
        ) : (
          <div className="quizAnswerSection">
            <div className="quizQuestionHeader">
              <h4 className="quizQuestion">
                {currentQuestion + 1}
                {"."} {questionList[currentQuestion]?.question}
              </h4>
              {/* {questionList[currentQuestion]?.isQuestionImageUploaded && (
                <img
                  className="quizQuestionImage"
                  src={questionList[currentQuestion]?.questionImage}
                />
              )} */}
              <p className="quizQuestionType">
                {
                  quizQuestionDisclimer[
                    questionList[currentQuestion]?.questiontype
                  ]
                }
              </p>
            </div>
            <div
              className={`${
                questionList[currentQuestion]?.isQuestionImageUploaded
                  ? "imagequestionWrapper"
                  : ""
              }`}
            >
              {questionList[currentQuestion]?.isQuestionImageUploaded && (
                <div className="quizQuestionImageBox">
                  <img
                    className="quizQuestionImage"
                    src={questionList[currentQuestion]?.questionImage}
                  />
                </div>
              )}
              {/* {questionList[currentQuestion]?.isQuestionImageUploaded && (<span className="dividerSpan">&nbsp;</span>  )} */}
              <div
                className={`${
                  questionList[currentQuestion]?.isQuestionImageUploaded &&
                  questionList[currentQuestion]?.imageSubType !== "Image"
                    ? "imagequestionWidth"
                    : ""
                }`}
              >
                {(questionList[currentQuestion]?.questiontype ===
                  "multiplechoice" ||
                  questionList[currentQuestion]?.questiontype === "image") && (
                  <MCQ
                    answerList={questionList[currentQuestion]?.optionArray}
                    indexValue={currentQuestion}
                    isImageOption={
                      questionList[currentQuestion]?.imageSubType === "Image"
                    }
                    validateAnswer={validateAnswer}
                    currentOptionSelected={currentOptionSelected}
                  />
                )}
                {questionList[currentQuestion]?.questiontype ===
                  "multipleOptions" && (
                  <MultiOption
                    answerList={questionList[currentQuestion]?.optionArray}
                    indexValue={currentQuestion}
                    validateAnswer={validateAnswer}
                    currentOptionSelected={currentOptionSelected}
                  />
                )}
                {questionList[currentQuestion]?.questiontype === "sequence" && (
                  <SequenceAnswer
                    answerList={questionList[currentQuestion]?.optionSqu}
                    indexValue={currentQuestion}
                    validateAnswer={validateAnswer}
                    currentOptionSelected={currentOptionSelected}
                  />
                )}
                {questionList[currentQuestion]?.questiontype === "match" && (
                  <MatchAnswer
                    answerList={questionList[currentQuestion]?.optionMatch}
                    indexValue={currentQuestion}
                    validateAnswer={validateAnswer}
                    currentOptionSelected={currentOptionSelected}
                  />
                )}
                {questionList[currentQuestion]?.questiontype ===
                  "trueorfalse" && (
                  <TrueOrFalseOption
                    validateAnswer={validateAnswer}
                    indexValue={currentQuestion}
                    currentOptionSelected={currentOptionSelected}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <div
          className={`btn-wrapper ${
            showNextButton || isReview ? "noskip" : ""
          }`}
        >
          {!showNextButton &&
            !showReviewBtn &&
            questionList.length !== currentQuestion + 1 &&
            !isReview && (
              <img
                src="/assets/quiz/skip.png"
                onClick={() => handleSkip(currentQuestion)}
                className="quizSkipArrow"
              />
              // <button
              //   onClick={() => handleSkip(currentQuestion)}
              //   className="quizSkip"
              // >
              //   Skip
              // </button>
            )}
          {showReviewBtn && !isReview && (
            <button
              disabled={!showNextButton}
              onClick={() => handleReview()}
              className="quizReview btn"
            >
              Review
            </button>
          )}
          {isReview && (
            <button
              onClick={() => handleFinalSubmit()}
              className="quizReview btn submitbtn"
            >
              Submit
            </button>
          )}
          {!showReviewBtn && (
            <img
              src="/assets/quiz/next.png"
              onClick={() =>
                handleNext(
                  showReviewBtn && checkForSkippedQuestionOnly ? true : false
                )
              }
              className={`quizNextArrow ${
                !showNextButton ? "disabledNext" : ""
              }`}
            />
            // <button
            //   disabled={!showNextButton}
            //   onClick={() =>
            //     handleNext(
            //       showReviewBtn && checkForSkippedQuestionOnly ? true : false
            //     )
            //   }
            //   className="quizNext btn"
            // >
            //   Next <img src="/assets/arrow.png" />
            // </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Quiz;
