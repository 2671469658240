import Card from "react-bootstrap/Card";
import "./course-cards.scss";
import { imagePath } from "../../constants/api";
import { Button } from "react-bootstrap";
import { dateDiff } from "../../utils/common";
import CalenderIcon from "../../assets/calendar_month_gray.svg";
import MenuBookIcon from "../../assets/menu_book.png";
import dayjs from "dayjs";
import { useState } from "react";
import { nursedescription } from "../../constants/constants";
const PackageCourseCards = (props: any) => {
  const [showMore, setShowMore] = useState(false);
  const cardDescriptionData = props.course?.description
    ? props.course?.description
    : nursedescription;
  return (
    <Card className="card-wrapper">
      <Card.Img
        variant="top"
        src={props.course?.image ? props.course?.image : "assets/package.png"}
      />
      <Card.Body>
        {/* <Card.Title>{props.course?.title}</Card.Title> */}
        <Card.Text>
          {showMore
            ? cardDescriptionData
            : `${cardDescriptionData.substring(0, 140)}`}
          {cardDescriptionData.length > 140 && (
            <>
              <span>... </span>
              <span
                className="cardDescription"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Read Less" : "Read More"}
              </span>
            </>
          )}
        </Card.Text>
        <ul className="subjectdetails-box-suboptions">
          <li>
            <img src={MenuBookIcon} alt="menu-book" />
            {/* {`Total Number of List : ${props.course?.isPackage? props.course?.list?.length:1}`} */}
            {`Date Of Purchase : ${dayjs(props.course?.purchasedDate).format(
              "DD/MM/YYYY"
            )}`}
          </li>

          <li>
            <img src={CalenderIcon} alt="menu-book" />
            {`Number of Days Left :${dateDiff(props.course?.endDate)}`}
          </li>
        </ul>
        <Button
          variant="primary"
          onClick={() => props.buuttonFunction(props.course)}
          className="buynow-button"
          size="lg"
        >
          Take Test
        </Button>
      </Card.Body>
    </Card>
  );
};

export default PackageCourseCards;
