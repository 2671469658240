import Card from "react-bootstrap/Card";
import "./course-cards.scss";
import { imagePath } from "../../constants/api";
import BtnWrapper from "../../common/BtnWrapper";
import { Button } from "react-bootstrap";
import { useState } from "react";
const CourseCards = (props: any) => {
  const [showMore, setShowMore] = useState(false);
  console.log("props", props);
  return (
    <Card
      className="card-wrapper"
      data-aos="flip-left"
      data-aos-duration="1000"
    >
      <Card.Img variant="top" src={props.course.image} />
      <Card.Body>
        {/* {!props.isSemester && props.purchaseType !== "Subject" && (
          <Card.Title>
            {props.course.shortTitle
              ? props.course.shortTitle
              : props.course.title}
          </Card.Title>
        )} */}

        <Card.Text>
          {showMore
            ? props.course.description
            : `${props.course.description.substring(0, 140)}`}
          {props.course.description.length > 140 && (
            <>
              <span>... </span>
              <span
                className="cardDescription"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Read Less" : "Read More"}
              </span>
            </>
          )}
        </Card.Text>
        {props.isPrice && (
          <div className="price">
            <div className="details">
              {props.course.discountPrice &&
              props.course.discountPrice !== 0 ? (
                <>
                  <span className="finel-price">
                    <span className="rupee-symbol">&#x20B9;</span>
                    {props.course.finalPrice}
                  </span>
                  {/* <span className="strike-price">
                  <span className="rupee-symbol">&#x20B9;</span>{props.course.finalPrice}
                  </span> */}
                </>
              ) : (
                <span className="finel-price">
                  <span className="rupee-symbol">&#x20B9;</span>
                  {props.course.finalPrice}
                </span>
              )}
            </div>
            {props.course.duration && (
              <span className="duration">
                Validity : {props.course.duration} <span>days</span>
              </span>
            )}
          </div>
        )}
        {props.isViewBtn ? (
          <>
            {!props.isSemester && (
              <BtnWrapper
                userType={props.userType}
                purchaseType={props.purchaseType}
                paydata={props.course}
              />
            )}

            <Button
              variant="primary"
              onClick={() => props.buuttonFunction(props.course)}
              className={`buynow-button mt-20 ${props.purchaseType === "Subject" ? "subjectBtn" : ""}`}
              size="lg"
            >
              {props.buttonText}
            </Button>
          </>
        ) : (
          <BtnWrapper
            userType={props.userType}
            purchaseType={props.purchaseType}
            paydata={props.course}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default CourseCards;
